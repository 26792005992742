<template>
  <div class="pb-2">
    <l-table
      ref="lTableRef"
      :table-config-options="tableConfigOptions()"
      :module-name="paymentMethodsModuleName"
      :table-columns="tableColumns"
      :can-create="ACCESS_ABILITY_FOR_PAYMENT_METHODS"
      :is-searchable="false"
      :can-show-trash-list="ACCESS_ABILITY_FOR_PAYMENT_METHODS"
      back-to-list-path="settings-payment-methods-list"
    >
      <template #cell(is_active)="{data}">
        {{ getStatus(data.value).name }}
      </template>
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <Can
            :i="ACCESS_ABILITY_FOR_PAYMENT_METHODS.action"
            :a="ACCESS_ABILITY_FOR_PAYMENT_METHODS.subject"
          >
            <feather-icon
              v-b-tooltip.noninteractive.hover
              :title="$t('Set to active')"
              icon="LLoadIcon"
              size="24"
              class="cursor-pointer mr-1 border-dotted defaultIconColor"
              @click="restoreContact(data.item)"
            />
            <feather-icon
              v-b-tooltip.noninteractive.hover
              :title="$t('Delete')"
              icon="LTrashIcon"
              size="24"
              class="cursor-pointer mr-1 border-dotted defaultIconColor"
              @click="remove(data.item)"
            />
          </Can>
        </div>
      </template>
    </l-table>
  </div>
</template>

<script>
import LTable from '@/views/components/LTable/LTable.vue'
import { VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import tableConfig from '../config'

export default {
  name: 'TrashList',
  components: { LTable },
  inject: ['paymentMethodsModuleName'],
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapGetters('listModule', ['getStatus']),
  },
  methods: {
    tableConfigOptions() {
      return {
        sortBy: 'created_at',
        data: {
          is_active: 0,
        },
      }
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.paymentMethodsModuleName}/del`).then(() => {
        this.refetchData()
      })
    },
    restoreContact(item) {
      this.confirmNotification(this, { id: item.id, is_active: true }, `${this.paymentMethodsModuleName}/update`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
  },
  setup() {
    const { tableColumns, ACCESS_ABILITY_FOR_PAYMENT_METHODS } = tableConfig()
    return {
      tableColumns,
      ACCESS_ABILITY_FOR_PAYMENT_METHODS,
    }
  },
}
</script>
<style scoped lang="scss">

</style>
